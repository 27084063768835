












































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import './scss/PopupEditProjectMember.scss';
import { IMemberRole, IProject, IProjectState } from '@store/modules/project/Interfaces';
import { namespace } from 'vuex-class';
import { ProjectTeamActions } from '@store/modules/project-team/Types';
import { IUserState } from '@store/modules/user/Interfaces';
import { IMemberInfo, IProjectTeamState } from '@store/modules/project-team/interfaces/Interfaces';
import { formatDate } from '@utility/formatDate.js';
import { isEmail } from '@utility/rules.js';
import { IJuristicEntity } from "@store/modules/about-project/Interfaces";

const NSAboutProject = namespace('storeAboutProject');
const NSProject = namespace('storeProject');
const NSProjectTeam = namespace('storeProjectTeam');
const NSUser = namespace('storeUser');

@Component({
    name: 'PopupEditProjectMember',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseFileInput: () => import('@components/BaseFileInput/BaseFileInput.vue'),
    }
})

export default class PopupEditProjectMember extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
    }

    @Prop({}) selectedItem!: any;

    @NSAboutProject.Getter('juristicEntities') juristicEntities!: IJuristicEntity[];
    @NSProjectTeam.State((state: IProjectTeamState) => state.viewedMember) viewedMember!: IMemberInfo;
    @NSProjectTeam.Getter('projectTeam') projectTeam!: IMemberInfo[];
    @NSProjectTeam.Action(ProjectTeamActions.A_EDIT_PROJECT_MEMBER) editProjectMemberGeneral!:(payload) => Promise<Promise<void>>;
    @NSUser.Getter('userData') userData!: IUserState;
    @NSProject.State((state: IProjectState) => state.memberRoles) memberRoles!: IMemberRole[];
    @NSProject.Getter('projectData') projectData!: IProject;

    @Watch('selectedItem')
    onChangeSelectedItem() {
        this.fields.id = this.selectedItem.uuid;
        this.fields.name = this.selectedItem.name;
        this.fields.email = this.selectedItem.email;
        this.fields.phone = this.selectedItem.phone;
        this.fields.role = this.selectedItem.role.uuid;
        this.fields.position = this.selectedItem.position;
        this.companiesList.forEach((item) => {
            if (item.label && item.label === this.selectedItem.legalEntity) {
                this.selectCompany(item);
            }
        });
    }

    @Watch('juristicEntities')
    onJuristicEntities(): void {
        this.companiesList.forEach((item) => {
            if (item.label && item.label === this.selectedItem.legalEntity) {
                this.selectCompany(item);
            }
        });
    }

    realtimeValidation = false;
    btnDisabled = false;
    fields = {
        id: '',
        name: '',
        position: '',
        email: '',
        role: '',
        juristicPerson: '',
        phone: '',
    };
    selectedCompanyText: string = '';
    errors = {
        name: false,
        position: false,
        email: false,
        phone: false,
    };
    errorAfterRequest: boolean = false;
    errorText: string = '';
    errorEmptyText: string = 'Вы не заполнили одно или несколько обязательных полей';
    errorAddressFormat: string = 'Указанный E-mail недоступен или имеет ошибочный формат';
    errorEmailExist: string = 'Указанный E-mail уже существует';

    get projectId() {
        return this.projectData ? this.projectData.id.toString() : '';
    }

    get titleForSelectRole() {
        if (this.fields.role) {
            const roleItem = this.memberRoles.filter(item => item.uuid === this.fields.role);
            if (roleItem.length) {
                return roleItem[0].value;
            }
        }
        return 'Выберите роль';
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    get companiesList(): any[] {
        const output: any[] = [];
        if (this.projectData && this.juristicEntities) {
            this.juristicEntities.forEach((entity: IJuristicEntity) => {
                output.push({
                    uuid: entity.id,
                    label: entity.title,
                    value: entity.title,
                })
            })
        }
        return output;
    }

    get dateToday() {
        return formatDate({ date: new Date(), format: 'yyyy-mm-dd'});
    }

    get otherMembers(): IMemberInfo[] {
        return this.projectTeam.filter((member: IMemberInfo) => member.uuid !== this.viewedMember.uuid);
    }

    get otherMemberEmails(): string[] {
        return this.otherMembers.map((member: IMemberInfo) => member.email);
    }

    get emailExist(): boolean {
        return this.otherMemberEmails.includes(this.fields.email);
    }

    selectRole(e) {
        this.fields.role = e.uuid;
        this.checkValidation();
    }

    selectCompany(e) {
        this.fields.juristicPerson = e.uuid;
        this.selectedCompanyText = e.value;
        this.checkValidation();
    }

    openPopup() {
        this.btnDisabled = false;
        if (this.$refs.basePopup) {
            this.$refs.basePopup.openPopup();
        }
    }

    closePopup() {
        this.btnDisabled = true;
        if (this.$refs.basePopup) {
            this.$refs.basePopup.closePopup();
        }
        this.resetFields();
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateForm();
        }
    }

    validateForm() {
        this.errors.name = !this.fields.name;
        this.errors.email = !this.fields.email;

        if (!this.errorExists) {
            this.validateEmail();
        } else {
            this.errorText = this.errorEmptyText;
        }

        this.realtimeValidation = true;
    }

    onSuccess() {
        this.errorAfterRequest = false;
        this.validateForm();
        if (!this.errorExists) {
            this.btnDisabled = true;
            this.updateProjectMember();
        }
    }

    updateProjectMember() {
        this.editProjectMemberGeneral({
            userId: this.fields.id,
            fullName: this.fields.name,
            email: this.fields.email,
            phone: this.fields.phone,
        })
            .catch(() => {
                this.errorAfterRequest = true;
                this.validateEmail();
            })
            .finally(() => {
                if (!this.errorText && !this.errorAfterRequest) {
                    this.$emit('edit-project-member', this.fields.id);
                    this.closePopup();
                }
                this.btnDisabled = false;
            });
    }

    resetFields() {
        this.fields = {
            id: '',
            name: '',
            position: '',
            email: '',
            role: '',
            juristicPerson: '',
            phone: '',
        }
        this.errors = {
            name: false,
            position: false,
            email: false,
            phone: false,
        }
        this.errorAfterRequest = false;
        this.realtimeValidation = false;
        this.errorText = '';
    }

    validateEmail() {
        if (!isEmail(this.fields.email)) {
            this.errors.email = true;
            this.errorText = this.errorAddressFormat;
        } else if (this.emailExist) {
            this.errors.email = true;
            this.errorText = this.errorEmailExist;
        } else {
            this.errors.email = false;
            this.errorText = '';
        }
    }
}
